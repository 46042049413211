//Icons
@import "bootstrap/Custom/icons";


summary::-webkit-details-marker {
  display: none
}
summary:before {
  font-family: 'Material Icons';
  content: "\e5cc";
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  margin: -2px 0px 0px -2px;
  padding: 0;
  text-align: center;
  width: 20px;
}
details[open] summary:before {
  font-family: 'Material Icons';
  content: "\e5cf";
}
